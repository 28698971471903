<template>
    <div>
       <center> 
           <br /><br />
           <h1>404</h1>
           Page non trouvée...
           <br /><br /><br /><br /><br />
       </center>
    </div>
   
</template>

<script>
import {Auth, APIRequest} from "@simb2s/senseye-sdk";
import google from 'google';
import emitter from 'tiny-emitter/instance'
import Stripe from 'stripe';
import Footer from '../components/layout/Footer.vue'
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'PageNotFound',

   data() {
    return {
        
    }
  },
  props:["displayModalMenu","language"],
  methods:{
   
  },
  updated()
  {
      
  },
  
  components: {
    Footer,
    ContentLoader
  },
  async mounted()
  {
    
  }
}
</script>
<style scoped>
a,a.register_link
{
  color:#0000FF;
}
</style>